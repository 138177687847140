@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.light-grey {
  background-color: #f8f8f8;
}

.very-light-grey {
  background-color: #FCFCFC;
}

.peak-green {
  background-color: #1CC468;
}

.yellow {
  background-color: #ffd400;
}

.center {
  text-align: center;
}

.icon {
  width: 200px;
  border: 2px solid #5e22e6;
  background-color: #5e22e6;
  border-radius: 10px;
  margin: auto;
  font-family: Poppins;
  color: white;
}

.ant-card {
  width: 100%;
  margin-bottom: 20px;
}

.card-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.content-section {
  padding-top: 40px;
  padding-bottom: 40px;
  padding: 70px 3%;
}

body {
  font-size: 16px;
}

h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
}

.landing-flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.logo-box {
  width: 200px;
}

.logo {
  width: 100%;
}
.landing-container {
  padding: 4vw;
  text-align: left;
}

.landing-column-content {
  text-align: left;
}

.landing-sub-heading {
  font-size: 18px;
  color: #838888;
}


.landing-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.landing-button {
  padding: 11px 21px;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  background-color: #FF5733;
  color: white;
  font-size: 18px;
  margin-top: 30px;
  width: 200px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  border: 0px;
}

.landing-heading {
  margin-top: 10px;
  font-size: 36px;
  line-height: 45px;
 
  font-family: 'Poppins', sans-serif;

}

.section-heading {
  font-size: 36px;
  text-transform: uppercase;
  line-height: 40px;
  color: #121212;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 28px;
  margin-top: 16px;
}


.peak-logo {
  width: 300px;
  margin-bottom: 50px;
}


.container-2 {

    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  
}

.colour-block {
    background: #1CC468;
    width: 100px;
    height: 100%;
    z-index: 1;
}

.colour-block-triangle {
  background: #1CC468;
  width: 300px;
  transform: skew(-10deg); 
  height: 100%;
  z-index: 1;
}

.cta-banner-dark {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 260px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: rgb(33, 42, 47);
  background-image: url(https://assets.website-files.com/5e7adff…/5e7adff…_Pattern%20Shape.svg);
  background-position: 50% 50%;
  background-size: cover;
}

.cta-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  flex-direction: column;
  -ms-flex-pack: justify;
 
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.next-section-content {

  color: white;
  text-align: center;
}

.cta-button-container {
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 40px;
  
}

.button-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 60px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  color: #212a2f;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.navbar{
  position: sticky;
  padding: 0 20px;
  top: 0px;
  overflow: auto;
  color: #1E1F32;
  background-color: #ffd400;
  height: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  height: 80px;
  z-index: 100;
}

.nav-icon {
  margin-right: 16px;
  font-size: 20px;
  
}

.nav-text {
  margin-bottom: 0px;
}

.footer {
  position: sticky;
  padding: 0 20px;
  top: 0px;
  overflow: auto;
  color: white;
  background-color: #212a2f;
  height: 300px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
}
.footer-heading {
  font-size: 20px;
  font-weight: 500;
}

.footer-button-box {
  margin-top: 20px;
}

/* Landing Page */ 

.homepage-hero {
  position: relative;
  height: 90vh;
  min-height: 500px;
}

.homepage-hero-image {
  height: 100%;
background-color: rgb(245, 245, 245);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 50%;
  opacity: 0.3;
}

.homepage-hero-heading {
  z-index: 10;
  padding: 0 10px;
  text-align: center;
}

.homepage-heading {
  font-size: 36px;

  font-weight: 700;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  
}

.heading-details {
  font-size: 20px;
}

.title-centre {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.display-heading {
  font-size: 52px;
  max-width: 560px;
  margin-top: 0px;
  margin-bottom: 24px;
  color: #2d3958;
  line-height: 1.07;
  font-weight: 700;
  letter-spacing: -0.03em;

}

.display-sub-heading {
  font-size: 42px;
  max-width: 560px;
  margin-top: 0px;
  margin-bottom: 24px;
  color: #2d3958;
  line-height: 1.07;
  font-weight: 700;
  letter-spacing: -0.03em;

}

.hero-details {
  max-width: 690px;
  color: #29335c;
  font-size: 18px;
  line-height: 1.45;
  font-weight: 400;
}


.content-container {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.shop-product-image-box {
  width: 100%;
  background-color: rgb(245, 245, 245);
  
}

.thank-you-icon {
  font-size: 40px;
  margin-bottom: 20px;
}

.thank-you-heading {
  font-size: 30px;
    font-weight: 600;
    line-height: 1.32;
    letter-spacing: .5px;
    color: #212a2f;
    margin: 0;
    padding: 0;
}

.wait-time-image {
  width: 400px;
  height: 125px;
  background-color: rgb(245, 245, 245);
  color: black;
  background-color: rgb(245, 245, 245);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212a2f;
  color: white;
  flex-direction: column;
}

.wait-time-time {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 0px;
}

.wait-time-heading {
  font-size: 20px;
  margin: 0px;
}

.long-line {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EC4723;
  padding: 5px;
  border-radius: 24px;
  width: 200px;
}

.long-line-icon {
  font-size: 18px;
}

.long-line-text {
  margin-bottom: 0px;
  margin-left: 10px;
}

.submissions-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  color: black;
}

.submission-text {
  font-size: 14px;
  margin-bottom: 0px;
  margin-left: 10px;
  color: black;
}

.help-text {
  font-size: 16px;
  color: black;
}

.submission-icon {
  font-size: 16px;
}

.guide-heading {
  
  position: relative;
  display: flex;
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(255, 133, 119);
  border-radius: 50px;
  height: 40px;
  padding: 20px;
  color: white;
  font-weight: 500;
    font-size: 14px;
    width: 70%;
    margin-bottom: 16px;
    margin-top: 16px;
}

.small {
  font-size: 34px;
}
.shop-product-clickable-details {
  padding: 12px 18px 0px;
}

.shop-product-title {
  margin: 0px 0px 6px;
}

.bold-title {
  font-weight: 700;
  line-height: 1.43;
  font-size: 14px;
  letter-spacing: 0.4px;
  margin: 0px;
  padding: 0px;
  color: rgb(33, 42, 47);
}

.filter-heading {
  font-weight: 700;
  line-height: 1.43;
  font-size: 18px;
  letter-spacing: 0.4px;
  margin: 0px;
  padding: 0px;
  color: rgb(33, 42, 47);
  margin-bottom: 16px;
}

.shop-product-detail {
  font-weight: 400;
  line-height: 1.43;
  font-size: 14px;
  letter-spacing: 0.4px;
  margin: 0px;
  padding: 0px;
  color: rgb(33, 42, 47);
  margin-bottom: 16px;

}

.price-box {
  padding: 0px 18px;
  margin-top: 16px;
}

.red {
  background-color: #D73917;
}

.font-red {
  color: #D73917
}

.font-white {
  color: white;
}

.green {
  background-color:green;
}

.shop-product-quick-add-box {
font-weight: 400;
    line-height: 1.43;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin: 0px;
    padding: 0px;
    color: rgb(33, 42, 47);
    margin-bottom: 16px;

}

.form {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.select-form input {
  font-size: 16px;
}


.location-form {
  max-width: 600px;
  margin: auto;
}

.region-selector-container {
  max-width: 700px;
  margin: auto;
}

.wait-time-form {
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
}

.shop-product-quick-add {
 
    position: relative;
    z-index: 2;
    appearance: none;
    width: 100%;
    padding: 9px 9px 9px 18px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    outline: none;
    border-width: 1px 0px 0px;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image: initial;
    border-top-style: solid;
    border-top-color: rgb(211, 212, 213);
    text-align: left;
    display: flex;
    height: 40px;
    align-items: center;

}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  position: relative;
  padding: 12px 16px 12px 40px;
  color: rgba(0,0,0,.85);
  line-height: 1.5715;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;

}
.form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 16px;
}

.select-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.location-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 700px;
  margin-top: 24px;
  margin-bottom: 20px;
}

.thank-you-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 700px;
  margin: auto;
  margin-top: 24px;
}

.wait-time-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 20px;
}

.wait-input {
  margin-top: 20px;
}

.ant-input-number {
  width: 90%;
  height: 40px;
}

.redirect-link {
  max-width: 700px;
  margin: auto;
}


.heading {
  font-size: 18px;
  font-weight: 600;
}

.redirect-button {
  display: inline-block;
  height: 52px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 18px;
  font-weight: 600;
  border: 2px solid #212a2f;
  border-radius: 2px;
  color: #212a2f;
  background-color: #fff;
  transition: color .25s ease-out,background-color .25s ease-out,border-color .25s ease-out;
  margin-top: 30px;
  margin-bottom: 30px;
}


.button-box {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}



.low-padding {
 
  padding: 20px;
  padding-top: 40px;
}

.more-detail-panel {
  padding: 10px;
}

.button-title {
  margin-right: 20px;
}

.card-loop {
    min-height: 160px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.form-label {
  font-size: 20px;
    font-weight: 600;
    line-height: 1.32;
    letter-spacing: .5px;
    color: #212a2f;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
}


.ant-select-selector {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  height: 50px;
  margin-top: 16px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
}

.ant-select-selection-item {
  white-space: pre-wrap;
}


.ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-arrow {
  top: 60%;
}

.ant-input-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.ant-input-number-input {
  text-align: center;
  font-size: 20px;
}

.more-detail-panel a {
  text-decoration: underline;
  color: blue
}


.shadow-box {
  box-shadow: rgb(211, 212, 213) 0px 6px 12px 0px;
}





.shop-product-card {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
  -webkit-transition: box-shadow .7s;
  transition: box-shadow .7s;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
 
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(min-width: 830px) {
  .navbar{
    position: sticky;
    padding: 0 20px;
    top: 0px;
    overflow: auto;
    height: auto;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    height: 80px;
    z-index: 100;
  }

  .ant-card {
    width: calc(33% - 20px)
  }

  .card-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .nav-icon {
    margin-right: 10px;
    font-size: 20px;
   
  }

  .homepage-hero {
    position: relative;
    height: 95vh;
    min-height: 500px;
  }


.homepage-hero-heading {
  z-index: 10;
  text-align: center;
  margin-top: 50px;
}

  .section-heading {
    font-size: 36px;
    text-transform: uppercase;
    line-height: 40px;
    color: #121212;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 28px;
    margin-top: 16px;
  }
  
  .homepage-heading {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    
  }

}

@media(min-width: 1000px) {

  .landing-container {
    width: 50%;
}

.landing-flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.invert {
    display: flex;
    flex-direction: row-reverse;
}

.landing-image-container {
    width: 50%;
}

.shop-product-card {
  width: calc(50% - 30px);
  max-width: 500px;
  display: inline-block;
  float: none;
  padding: 15px;

  margin: 15px;
  position: relative;

  -webkit-transition-delay: .1s;
  transition-delay: .1s;
 

}

.cta-banner-dark {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 260px;
  padding: 60px 70px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: rgb(33, 42, 47);

  background-position: 50% 50%;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 30px;
}

.cta-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.next-section-content {
  width: 50%;
  color: white;
  text-align: left;
}

.cta-button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta-button-container {
    
  margin: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 300px;
}

.button-cta {
  font-size: 16px;
  width: 100%
}

.container-2 {

  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

}

}